import React from "react";
import { Card, CardBody } from "reactstrap";
import { Href } from "../../Constants";
import { formatToMillions } from "../../Utils";

const LiquidityDetailCard = ({ addressData }) => {
  return (
    <Card className="liquidity">
      <CardBody>
        <div className="usdc-title">
          <div className="avail-title">
            <h2>
              <span>avail / usdc</span>
            </h2>
          </div>
          <div className="base">
            <ul>
              <a href={Href}>
                <img src={`../assets/svg/base.svg`} alt="" />
                base
              </a>
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
              <a href={Href}>
                <img src={`../assets/svg/aerodrome.svg`} alt="" />
                aerodrome
              </a>
            </ul>
          </div>
        </div>

        <div className="price-usd">
          <div className="custom-price">
            <span>Price USD</span>
            <span>
              <div className="usd">$ {Number(addressData?.price_usd)?.toFixed(2)} </div>
            </span>
          </div>
          <div className="custom-price">
            <span>Liquidity</span>
            <span>
              <div className="usd">{(addressData?.liquidity||0)}</div>
            </span>
          </div>
          <div className="custom-price">
            <span>Volume</span>
            <span>
              <div className="usd">{`$ ${Number(addressData?.liquidity_volume)/1000} K` }</div>
            </span>
          </div>
          <div className="custom-price">
            <span>Pooled Avail</span>
            <span>
              <div className="usd">{formatToMillions(addressData?.pooled_avail)}</div>
            </span>
          </div>
          <div className="custom-price">
            <span>Pooled Usdc</span>
            <span>
              <div className="usd">$ {formatToMillions(addressData?.pooled_usdc,1)}</div>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LiquidityDetailCard;
