// Auth
export const loginApi = "api/auth/login";
export const forgotPasswordApi = "api/auth/forgotpassword";
export const verifyEmailTokenApi = "api/auth/verifyEmail";
export const resetPasswordApi = "api/auth/resetpassword";

// Statistics
export const getStatisticsApi = "/api/getStatics";

// Transaction
export const transactionApi = "api/getTransactions";
export const updateTransactionApi = "api/transaction/update";
export const importTransactionApi = "api/import/transaction";

// Accounts
export const updateAccountApi = "api/account/update";
export const importAccountApi = "api/import/account";
export const refreshAccountApi = "api/transaction/refresh";
export const createAccountApi = "api/account/create";
export const deleteAccountApi = "/api/account/delete";
export const getAccountApi = "/api/getAccounts";

// Budget
/* Old */
export const getBudgetApi = "api/getBudgets";
export const createBudgetApi = "api/createBudget";
export const updateBudgetApi = "api/updateBudget";
export const deleteBudgetApi = "/api/deleteBudget";

/* New */
export const importBudgetApi = "api/import/budgetData";
export const getBudgetDataApi = "/api/getBudgetData";
export const createOrUpdateBudgetApi = "api/budget/createOrUpdateBudgetData";
export const deleteBudgetDataApi = "/api/budget/deleteBudgetData";
export const uploadSignedAgreementApi = "api/uploadSignedAgreement";
export const removeAgreementApi = "api/removeSignedAgreement";
export const budgetStatisticsApi = "api/budget/getStatics";
export const budgetChartApi = "api/budget/getBudgetStaticsChart";

// Bridge Transfer
export const getBridgeTransfersApi = "api/getBridgeTransfers";
export const updateBridgeTransferApi = "api/bridgeTrasfer/update";

// Notifications
export const getNotificationsApi = "/api/getAllNotification";
export const readAllNotificationsApi = `/api/markAsReadNotification`;

// Billing (deel)
export const getDeelInvoicesApi = "/api/deel/invoices";
export const getDeelPaymentHistoriesApi = "/api/deel/paymentHistories";
export const getDeelExpensesApi = "api/deel/expenses";
export const getDeelPeoplesApi = "/api/deel/peoples";

export const deelExpenseRefreshApi = "api/deel/expense";
export const deelPeopleRefreshApi = "api/deel/people";
export const deelPaymentHistoryRefreshApi = "api/deel/paymentHistory";
export const deelInvoiceRefreshApi = "api/deel/invoice";

//User Role
export const userRole = "/api/role";
export const userRoleCreate = "api/role/create";
export const userRoleUpdate = "api/role/update";
export const userRoleDelete = "/api/role/destroy";

// User
export const user = "/api/user";
export const userCreate = "api/user/create";
export const userUpdate = "api/user/update";
export const userDelete = "/api/user/destroy";

//Liquidity
export const getLiquiditiesApi = "/api/getLiquidities";
export const syncLiquidities = "/api/syncLiquidities";
export const getPoolAddresses = "/api/getPoolAddresses";
export const createPoolAddress = "api/createPoolAddress";
export const availStatistics = "/api/availStatistics";
export const coinMarketApi = "/api/coinMarketApi";