import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col } from "reactstrap";
import { formatAmount, formatDateTime } from "../../../Utils";

const TotalHoldersAndVolumeStats = ({ data,dateSeries }) => {
  const categories = data?.map((item) => formatDateTime(item.date));
  const priceSeries = data?.map((item) => item?.avail_account_holders + item?.eth_account_holders + item?.eth_account_holders + item?.eth_account_holders);

  const volumeSeries = data?.map((item) => item.prev_volume);
  const [chartType, setChartType] = useState(false);

  const updateChartType = () => {
    setChartType((prev) => !prev);
  };

  const series = [
    {
      name: "Total Volume",
      type: chartType ? "line" : "bar",
      data: volumeSeries,
      color: "#2dd4bf",
    },
    {
      name: "Total Holders",
      type: chartType ? "line" : "bar",
      data: priceSeries,
      color: "#07575b",
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
          customIcons: [
            {
              icon: `${chartType ? `<i class="fa-solid fa-chart-simple"></i>` : '<i class="fa-solid fa-chart-line"></i>'}`, // Add custom icon (can also use SVG path)
              title: "Chart Type", // Tooltip text
              index: "start", // Position in the toolbar
              click: updateChartType,
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "Total Holders And Volume Stats",
      style: {
        fontSize: "18px",
        color: "var(--table-font-color)",
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: [
      {
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          // color: "#2dd4bf",
        },
        labels: {
          style: {
            colors: "#2dd4bf",
          },
          formatter: (value) => formatAmount(value),
        },
        title: {
          text: "Volume",
          style: {
            // color: "#2dd4bf",
          },
        },
        tooltip: {
          enabled: true,
        },
      },

      {
        seriesName: "Cashflow",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#07575b",
        },
        labels: {
          style: {
            colors: "#07575b",
          },
          formatter: (value) => formatAmount(value),
        },
        title: {
          text: "Holders",
          style: {
            color: "#07575b",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <Col lg="12">
      <Card className="common-chart">
        <CardBody>
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalHoldersAndVolumeStats;
