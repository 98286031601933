import { formatAmount, handleCapitalizeMultiple } from "../../Utils";

export const InvoiceTableColumnData = [
  { key: "due_date", label: "Due Date", type: "date" },
  { key: "paid_at", label: "Paid At", type: "date" },
  { key: "label", label: "Label" },
  { key: "currency", label: "Currency" },
  { key: "contract_id", label: "Contactor Id" },
  { key: "total", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status?.toString())}</span> },
];

export const PaymentHistoryTableColumnData = [
  { key: "paid_at", label: "Paid At", type: "date" },
  { key: "label", label: "Label" },
  { key: "workers", label: "Workers", render: (item) => (item?.workers?.length ? item?.workers?.map((item) => item?.name)?.join(",") : "--") },
  { key: "payment_currency", label: "Payment Currency" },
  { key: "total", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status?.toString())}</span> },
];

export const BridgeTransferTableColumnData = [
  { key: "hash", label: "Hash", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/tx/" },
  { key: "from_account", label: "From Account", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/address/" },
  { key: "to_account", label: "To Account", type: "address", truncate: true, redirectLinkPrefix: "https://avail.subscan.io/account/" },
  { key: "amount", label: "Amount", type: "amount" },
  { key: "timestamp", label: "Timestamp", type: "date" },
];

export const ExpensesTableColumnData = [
  { key: "expense_id", label: "Expense Id", type: "address", truncate: true },
  { key: "reported_by.full_name", label: "Reported By" },
  { key: "description", label: "Description" },
  { key: "type", label: "Type", render: (item) => handleCapitalizeMultiple(item?.type) },
  { key: "total_amount", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" || item?.status?.toString() === "approved" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
];

export const LiquidityTableColumnData = [
  {
    key: "id",
    label: "No.",
  },
  {
    key: "block",
    label: "Block",
    type: "link",
    redirectLinkPrefix: "https://basescan.org/block/",
  },
  {
    key: "type",
    label: "Type",
    render: (item) => <span className={`badge badge-light-${item?.type === "BUY" || item?.type === "BUY AVAIL" ? "success" : "danger"}`}>{handleCapitalizeMultiple(item?.type)}</span>,
    isSortable: true,
  },
  {
    key: "tx_hash",
    label: "Transaction Hash",
    type: "address",
    tooltipContent: "to_account",
    tooltipColor: "success",
    truncate: true,
    redirectLinkPrefix: "https://basescan.org/tx/",
  },
  {
    key: "usdc_amount",
    label: "USDC Amount",
    type: "amount",
    isSortable: true,
  },
  {
    key: "avail_amount",
    label: "Avail Amount",
    type: "amount",
  },
  {
    key: "price_usdc",
    label: "Price Usdc",
    type: "amount",
    isSortable: true,
  },
  {
    key: "slippage",
    label: "Slippage",
    render: (item) => formatAmount(item?.slippage) + "%",
  },

  {
    key: "timestamp",
    label: "Timestamp",
    type: "date",
    isSortable: true,
  },
];

export const NotificationTableColumnData = [
  {
    key: "notification",
    label: "Notification",
    render: (item) => (
      <div className={`${item?.mark_as_read ? "notify-push" : "notify-visible"}`}>
        <p>{item.title}</p>
        <span>{item.description}</span>
      </div>
    ),
  },
  {
    key: "status",
    label: "Status",
    render: (item) => <span className={`badge badge-light-${item?.status === "completed" ? "success" : "warning"}`}>{item?.status === "completed" ? "Executed" : "Pending"}</span>,
  },
];

export const PeoplesDataTableColumnData = [
  { key: "people_id", label: "People Id", type: "address", truncate: true },
  { key: "full_name", label: "Person" },
  { key: "birth_date", label: "Birth Date", type: "date" },
  { key: "timezone", label: "Timezone" },
  { key: "country", label: "Country" },
  { key: "job_title", label: "Job Title" },
  { key: "hiring_type", label: "Hiring Type", render: (item) => handleCapitalizeMultiple(item?.hiring_type) },
  { key: "hiring_status", label: "Hiring Status", render: (item) => <span className={`badge badge-light-${item?.hiring_status?.toString() === "active" ? "success" : "danger"}`}>{handleCapitalizeMultiple(item?.hiring_status)}</span> },
];

export const TransactionTableColumnData = [
  { key: "id", label: "No.", isSortable: true },
  {
    key: "extrinsic_id",
    label: "Extrinsic Id",
    isSortable: true,
    type: "link",
    redirectLinkPrefix: "https://avail.subscan.io/extrinsic/",
  },
  {
    key: "from_account",
    label: "From Account",
    type: "address",
    useTooltip: (item) => item.from_label,
    tooltipContent: "from_account",
    tooltipColor: "danger",
    redirectLinkPrefix: "https://avail.subscan.io/account/",
    truncate: true,
  },
  {
    key: "to_account",
    label: "To Account",
    type: "address",
    useTooltip: (item) => item.to_label,
    tooltipContent: "to_account",
    tooltipColor: "success",
    redirectLinkPrefix: "https://avail.subscan.io/account/",
    truncate: true,
  },
  {
    key: "amount",
    label: "Amount",
    isSortable: true,
    type: "amount",
  },
  {
    key: "timestamp",
    label: "Timestamp",
    isSortable: true,
    type: "date",
  },
];

export const UsersTableColumnData = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },

  {
    key: "role.name",
    label: "Role",
    render: (item) => <span className="badge badge-success">{item?.role?.name?.toUpperCase()}</span>,
  },
  {
    key: "created_at",
    label: "Created At",
    type: "date",
  },
];

export const RolesTableColumnData = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "created_at",
    label: "Created At",
    type: "date",
  },
];
