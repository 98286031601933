import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col } from "reactstrap";
import { formatAmount } from "../../../Utils";

const BaseHolderStats = ({ data, dateSeries }) => {
  const [chartType, setChartType] = useState(true); // Toggle between bar and line chart

  const updateChartType = () => {
    setChartType(!chartType); // Toggle the chart type
  };

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
          customIcons: [
            {
              icon: chartType ? `<i class="fa-solid fa-chart-simple"></i>` : '<i class="fa-solid fa-chart-line"></i>',
              title: "Chart Type",
              index: "start",
              click: updateChartType,
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "BASE Holder And Volume Stats",
      style: {
        fontSize: "18px",
        color: "var(--table-font-color)", // Replace with an actual color if needed
      },
    },
    xaxis: {
      categories: dateSeries,
    },
    yaxis: [
      {
        seriesName: "BASE Volume",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#155e75",
        },
        labels: {
          style: {
            colors: "#155e75",
          },
          formatter: (value) => formatAmount(value),
        },
        title: {
          text: "BASE Volume",
          style: {
            color: "#155e75",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "BASE Holders",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#2dd4bf",
        },
        labels: {
          style: {
            colors: "#2dd4bf",
          },
          formatter: (value) => formatAmount(value),
        },
        title: {
          text: "BASE Holders",
          style: {
            color: "#2dd4bf",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  const series = [
    {
      name: "BASE Volume",
      color: "#155e75",
      type: chartType ? "bar" : "line",
      data: data?.map((item) => item?.prev_dex_volume_base) , // Replace with actual data
    },
    {
      name: "BASE Holders",
      color: "#2dd4bf",
      type: chartType ? "bar" : "line",
      data: data?.map((item) => item?.base_account_holders), // Replace with actual data

      // data: [20, 30, 25, 40, 35], // Replace with actual data
    },
  ];

  return (
    <Col lg="12">
      <Card className="common-chart">
        <CardBody>
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default BaseHolderStats;
