import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { formatAmount } from "../../../Utils";

const HoldersDetailCard = ({ holdersChangePerDay, holderPercentage }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className="price-usd">
      <h5 className="text-center"> Account Holders</h5>
      <div className="custom-price">
        <div className={`price badge-light-${holdersChangePerDay?.percentageChange > 0 ? "success" : holdersChangePerDay?.percentageChange < 0 ? "danger" : "warning"}`}>
          <span id="refresh-tooltip" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
            <h6 className="mt-2">{formatAmount(holderPercentage?.grandTotal) || 0}</h6>
            <i className="fa-solid fa-circle-info"></i>
          </span>
          {document.getElementById("refresh-tooltip") ? (
            <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
              {holderPercentage?.grandTotal
                ? Object.entries(holderPercentage?.overallTotals || {}).map(([key, value], index) =>
                    value ? (
                      <div key={index}>
                        <p>
                          <b> {key}: </b> {value ? formatAmount(value) : 0}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  )
                : "No holders data found"}
            </Tooltip>
          ) : null}
          <span>
            <i className={`fa-solid fa-arrow-${holdersChangePerDay?.percentageChange > 0 ? "trend-up" : holdersChangePerDay?.percentageChange < 0 ? "trend-down" : ""}`}></i>
            <div className="usd">{Number(holdersChangePerDay?.percentageChange) || 0} %</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HoldersDetailCard;
