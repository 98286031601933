export const MenuList = [
  {
    type: "main",
    headingTitle: "General",
    mainMenus: [
      {
        id: 1,
        path: "/transaction",
        type: "link",
        active: true,
        title: "Transactions",
        icon: <i className="fa-solid fa-money-bill-transfer" />,
        permission: ["transaction.index"],
      },
      {
        id: 2,
        path: "/account",
        type: "link",
        active: false,
        title: "Account",
        icon: <i className="fa-solid fa-user" />,
        permission: ["account.index"],
      },

      {
        id: 3,
        type: "sub",
        active: false,
        title: "Budgeting",
        icon: <i className="fa-solid fa-coins" />,
        permission: ["budget.index"],
        children: [
          {
            id: 31,
            path: "/budget/bank_payments",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Bank Payments",
          },
          {
            id: 32,
            path: "/budget/credit_card_payments",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Credit Card Payments",
          },
          {
            id: 33,
            path: "/budget/ethereum_wallet",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Ethereum Wallet Payments",
          },
          {
            id: 34,
            path: "/budget/avail_wallet",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Avail Wallet Payments",
          },
          {
            id: 35,
            path: "/budget/avail_difc",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Avail 3rd Party Contract (DIFC)",
          },
          {
            id: 36,
            path: "/budget/avail_cayman",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Avail 3rd Party Contract (Cayman)",
          },
          {
            id: 37,
            path: "/budget/avail_bvi",
            type: "link",
            active: false,
            permission: ["budget.index"],
            title: "Avail 3rd Party Contract (BVI)",
          },
        ],
      },
      {
        id: 4,
        path: "/statistics",
        type: "link",
        active: false,
        title: "Statistics",
        permission: ["statistics.index"],
        icon: <i className="fa-solid fa-chart-line" />,
      },
      {
        id: 5,
        path: "/bridge_transfer",
        type: "link",
        active: false,
        title: "Bridge Transfer",
        icon: <i className="fa-solid fa-hand-holding-dollar" />,
        permission: ["bridge_transfers.index"],
      },
      {
        id: 9,
        path: "/notifications",
        type: "link",
        active: false,
        title: "Notifications",
        permission: ["notification.index"],
        icon: <i className="fa-solid fa-bell" />,
      },
      {
        id: 10,
        type: "sub",
        active: false,
        title: "Users",
        icon: <i className="fa-solid fa-address-book"/>,
        children: [
          {
            id: 64,
            path: "/users/all_user",
            type: "link",
            active: false,
            title: "All Users",
            permission: ["user.index"],
          },
          // {
          //   id: 65,
          //   path: "/users/add",
          //   type: "link",
          //   active: false,
          //   title: "Add User",
          // },
          {
            id: 66,
            path: "/users/role",
            type: "link",
            active: false,
            title: "Role",
            // permission: ["role.index"],
          },
        ],
      },

      {
        id: 9,
        path: "/decoder",
        type: "link",
        active: false,
        title: "Wallet",
        permission: ["wallet.index"],
        icon: <i className="fa-solid fa-wallet" />,
      },

      {
        id: 10,
        path: "/liquidity",
        type: "link",
        active: false,
        title: "Liquidity",
        permission: ["liquidity.index"],
        icon: <i className="fa-brands fa-ethereum" />,
      },
      {
        id: 5,
        path: "/avail_info",
        type: "link",
        active: false,
        title: "Avail Info",
        icon: <i className="fa-solid fa-chart-simple"></i>,
        permission: ["bridge_transfers.index"],
      },
    ],
  },
  {
    type: "main",
    headingTitle: "Deel Management",
    mainMenus: [
      {
        id: 7,
        path: "/peoples",
        type: "link",
        active: false,
        title: "Peoples",
        icon: <i className="fa-solid fa-users-gear" />,
        permission: ["deel_people.index"],
      },
      {
        id: 6,
        type: "sub",
        active: false,
        title: "Billing",
        icon: <i className="fa-solid fa-coins" />,
        children: [
          {
            id: 61,
            path: "/billing/payment_history",
            type: "link",
            active: false,
            title: "Payment History",
            permission: ["deel_payment_history.index"],
          },
          {
            id: 62,
            path: "/billing/invoices",
            type: "link",
            active: false,
            title: "Invoice",
            permission: ["deel_invoice.index"],
          },
        ],
      },
      {
        id: 8,
        path: "/expenses",
        type: "link",
        active: false,
        title: "Expenses",
        icon: <i className="fa-solid fa-file-signature" />,
        permission: ["deel_expense.index"],
      },
    ],
  },
];

export let storePermission = {
  permissions: [
    {
      id: 11,
      name: "user.index",
      guard_name: "web",
      created_at: "2024-05-02T08:34:45.000000Z",
      updated_at: "2024-05-02T08:34:45.000000Z",
      pivot: {
        role_id: 1,
        permission_id: 11,
      },
    },

    // {
    //   id: 11,
    //   name: "user.edit",
    //   guard_name: "web",
    //   created_at: "2024-05-02T08:34:45.000000Z",
    //   updated_at: "2024-05-02T08:34:45.000000Z",
    //   pivot: {
    //     role_id: 1,
    //     permission_id: 11,
    //   },
    // },

    {
      id: 11,
      name: "user.create",
      guard_name: "web",
      created_at: "2024-05-02T08:34:45.000000Z",
      updated_at: "2024-05-02T08:34:45.000000Z",
      pivot: {
        role_id: 1,
        permission_id: 12,
      },
    },
    {
      id: 11,
      name: "user.destroy",
      guard_name: "web",
      created_at: "2024-05-02T08:34:45.000000Z",
      updated_at: "2024-05-02T08:34:45.000000Z",
      pivot: {
        role_id: 1,
        permission_id: 12,
      },
    },
  ],
};
