import toast, { Toaster } from "react-hot-toast";

// CustomToaster
export const CustomToaster = () => (
  <Toaster
    toastOptions={{
      duration: 3000,
      position: "top-right",
      style: {
        fontWeight: 700,
        minWidth: 200,
      },
      success: {
        style: {
          background: "rgb(229, 245, 227)",
          color: "rgba(var(--success), 1)",
        },
      },
      error: {
        style: {
          background: "rgb(255, 238, 237)",
          color: "rgba(var(--error), 1)",
        },
      },
    }}
  />
);

// Dynamic Image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image) => {
  return images(`./${image}`);
};

const svg = require.context(`/public/assets/svg`, true);

export const dynamicSvg = (svgName) => {
  return svg(`./${svgName}`);
};

// Date Formate
export const formatDateTime = (timestamp, onlyDate = false) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // use 24-hour format
  };
  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Format date and time
  const formattedDate = date == "Invalid Date" ? "--" : date.toLocaleDateString("en-GB", onlyDate ? dateOptions : options).replace(/\//g, "-");

  return `${formattedDate}`;
};

//format number
export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num;
};

// thousand separator
// export const formatAmount = (amount) => {
//   return amount >= 1 ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount;
// };
export const formatAmount = (amount) => {
  const formattedAmount = Number(amount);
  if (typeof formattedAmount === "number" || !isNaN(formattedAmount)) {
    const [integerPart, decimalPart] = formattedAmount.toString().split(".");
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }
  return formattedAmount;
};

// turncate from middle
export const truncateMiddle = (text, maxLength) => {
  if (text.length <= maxLength) return text;

  const halfLength = Math.floor(maxLength / 2);
  return `${text.substring(0, halfLength)}...${text.substring(text.length - halfLength)}`;
};

// copy text
export const handleCopyCode = (id,text="Address") => {
 id ? navigator.clipboard.writeText(id).then(() => toast.success(`${text} copied to clipboard`)) :toast.error('Nothing copied to clipboard');
};

// convert given time in milliseconds
export const toMilliseconds = (hrs, min, sec) => (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const Capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const handleDrop = (event, setFieldValue) => {
  event.preventDefault();
  const droppedFiles = event.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const newFile = droppedFiles[0]; // Only taking the first file
    setFieldValue("data", newFile); // Set the dropped file in Formik's values
  }
  if (droppedFiles.length > 1) {
    return toast.error("Could not drop more than 1 file");
  }
};

// Prevent default behavior on dragover to allow drop
export const handleDragOver = (event) => {
  event.preventDefault();
};

export const handleCapitalizeMultiple = (str, needToReplace) => {
  return str
    ?.split(needToReplace ? needToReplace : "_")
    ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1, item?.length))
    ?.join(" ");
};

export const ConvertPermissionArr = (permissionsData) => {
  let tempName;
  let ansArray = [];
  let num = 0;

  permissionsData?.map((data) => {
    if (tempName == data.split(".")[0]) {
      ansArray[num - 1].permissionsArr?.push({ id: data.id, type: data.split(".")[1] });
    } else {
      num++;
      tempName = data.split(".")[0];
      ansArray.push({ name: data.split(".")[0], permissionsArr: [{ id: data.id, type: data.split(".")[1] }] });
    }
  });
  ansArray = [...ansArray];
  return ansArray;
};

export const formatToMillions = (amount, decimals = 2) => {
  let formatAmount = Number(amount);
  if (typeof formatAmount !== "number" || isNaN(formatAmount)) {
    formatAmount = Number(amount);
  }

  const million = 1_000_000; // 1 million
  const formattedAmount = (amount / million).toFixed(decimals);

  return `${formattedAmount}M`;
};

// export function calculatePercentChange(original, newVal) {
//   const originalValue = Number(original);
//   const newValue = Number(newVal);
//   // Calculate the absolute change
//   const absoluteChange = newValue - originalValue;

//   // Calculate the percentage change
//   const percentageChange = (absoluteChange / originalValue) * 100;

//   // Return both values in an object
//   return {
//     absoluteChange: absoluteChange.toFixed(6), // Rounds to 6 decimal places
//     percentageChange: percentageChange.toFixed(2), // Rounds to 2 decimal places
//   };
// }

export function calculatePercentChange(original, newVal) {
  const originalValue = Number(original);
  const newValue = Number(newVal);

  // Calculate the absolute change
  const absoluteChange = newValue - originalValue;

  // Handle the edge case where originalValue is 0
  let percentageChange = 0;
  if (originalValue !== 0) {
    percentageChange = (absoluteChange / originalValue) * 100;
  } else {
    percentageChange = newValue !== 0 ? 100 : 0; // If newValue is non-zero, set percentageChange to 100.
  }

  // Return both values in an object
  return {
    absoluteChange: absoluteChange.toFixed(6), // Rounds to 6 decimal places
    percentageChange: percentageChange.toFixed(2), // Rounds to 2 decimal places
  };
}

