import { ApiPromise } from "@polkadot/api";
import { WsProvider } from "@polkadot/rpc-provider";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useAuth } from "../Context/AuthContext";
import { useWallet } from "../Context/WalletContext";
import { useHeaderScroll } from "../Hooks/useHeaderScroll";
import HeaderNotifications from "./HeaderNotifications";
import Sidebar from "./Sidebar/Sidebar";
import ConnectWalletModal from "./WalletLayout/ConnectWalletModal";
import { initialize } from "avail-js-sdk";

const Layout = () => {
  const { toggleEdit, setApi, accounts, setCurrentModal, setIsExtensionInstalled, selectedAccount } = useWallet();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();
  const upScroll = useHeaderScroll(false);
  const { logout } = useAuth();
  const [toggle, setToggle] = useState(localStorage.getItem("isDark") === "true"); // Ensure boolean value

  const handleDarkLight = () => {
    const newTheme = !toggle;
    setToggle(newTheme);
    localStorage.setItem("isDark", newTheme);
    document.body.classList.toggle("dark-only", newTheme);
  };

  useEffect(() => {
    // Apply theme on initial load
    const isDark = localStorage.getItem("isDark") === "true";
    document.body.classList.toggle("dark-only", isDark);
  }, []);

  const handleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  // const connectApi = async () => {
  //   try {
  //     // const wsProvider = new WsProvider("wss://rpc.polkadot.io");
  //     const wsProvider = new WsProvider("wss://turing-rpc.avail.so/ws");
  //     const api = await ApiPromise.create({
  //       provider: wsProvider,
  //     });
  //     await api.isReady;
  //     setApi(api);
  //   } catch (error) {
  //     return error;
  //   }
  // };


  const connectApi = async () => {
    try {
      // const wsProvider = new WsProvider("wss://rpc.polkadot.io");
      const wsProvider = new WsProvider("wss://turing-rpc.avail.so/ws");
      const api = await initialize("wss://turing-rpc.avail.so/ws");
      await api.isReady;
      setApi(api);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    connectApi();
  }, [])
  

  const [isOpen, setIsOpen] = useState(true);

  const toggleClick = () => {
    document.querySelector(".page-header")?.classList.toggle("close_icon");
    document.querySelector(".sidebar-wrapper")?.classList.toggle("close_icon");
    setIsOpen(!isOpen);
  };

  const detectBlockchainWallet = () => {
    if (typeof window !== "undefined") {
      if (window.ethereum) {
        return true;
      } else if (window.web3) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const compactSidebar = () => {
      const windowWidth = window.innerWidth;
      const action = windowWidth < 1200 ? "add" : "remove";
      document.querySelector(".page-header")?.classList[action]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[action]("close_icon");
      const activeSidebar = windowWidth < 992 ? "add" : "remove";
      document.querySelector(".page-header")?.classList[activeSidebar]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[activeSidebar]("close_icon");
    };

    compactSidebar();
    window.addEventListener("resize", compactSidebar);
    // connectApi();
    const foundExtension = detectBlockchainWallet();
    if (foundExtension) setIsExtensionInstalled(true);
    else setIsExtensionInstalled(false);
    // const extensions = web3Enable("Avail Transaction Decoder").then(() => {
    //   if (extensions.length === 0) {
    //     setIsExtensionInstalled(false);
    //   } else {
    //     setIsExtensionInstalled(true);
    //   }
    // });
    return () => window.removeEventListener("resize", compactSidebar);
  }, []);

  const handleModalButtonClick = () => {
    if (accounts?.length) {
      setCurrentModal("account");
    }
    toggleEdit();
  };

  return (
    <div className={`page-wrapper`}>
      <ConnectWalletModal />
      <div className={`page-body-wrapper`}>
        <div className={`page-header ${isSidebarOpen ? "close_icon" : ""}`}>
          <Container fluid className={`${upScroll ? "sticky" : ""} main-navbar d-flex justify-content-between container-fluid p-0`}>
            <div className="toggle-sidebar" onClick={toggleClick}>
              <div className="sidebar-toggle">
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
            <div className="header-search">
              <input type="text" className="search-bar" placeholder="Search....." />
            </div>
            <ul className={`common-flex right-navbar`}>
              {selectedAccount?.meta?.name && (
                <p className="custom-user-btn">
                  <img src="../assets/svg/sub-wallet-icon.svg" alt="" /> {selectedAccount?.meta?.name}{" "}
                </p>
              )}
              <Button className="btn btn-dark" onClick={handleModalButtonClick}>
                {accounts?.length ? "Accounts" : "Connect Wallet"}
              </Button>
              <li className={`light-box search-icon-box`}>
                <i className="fa-solid fa-magnifying-glass"></i>
              </li>
              <div className="form-group search-form open d-none">
                <input type="text" placeholder="Search here..." />
              </div>
              <li onClick={handleFullscreen} className={`light-box ${toggle ? "active" : ""}`}>
                {!isFullScreen ? <i className="fa-solid fa-compress"></i> : <i className="fa-solid fa-expand"></i>}
              </li>
              <li onClick={handleDarkLight} className={`light-box ${toggle ? "active" : ""}`}>
                {toggle ? <img className="img-dark" src={`../assets/svg/moon.svg`} alt="" /> : <img src={`../assets/svg/sun.svg`} alt="" />}
              </li>

              <HeaderNotifications />
              <li>
                <Button className="signout" color="dark" onClick={() => logout(navigate)}>
                  Sign Out
                </Button>
              </li>
            </ul>
          </Container>
        </div>

        <div className="page-body">
          <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

          <div className="page-main-body">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
