import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a, isNumber } from "@polkadot/util";
import { BN, signedExtensions } from "avail-js-sdk";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { useWallet } from "../../Context/WalletContext";
import Loader from "../../Layout/Loader";
import { dynamicSvg, handleCopyCode } from "../../Utils";
import TableTop from "../Common/TableTop";

const AvailWalletContainer = () => {
  const { api, selectedAccount } = useWallet();
  const [callData, setCallData] = useState("");
  const [decodedData, setDecodedData] = useState(null);
  console.log("decodedData", decodedData);

  const [isDecoding, setIsDecoding] = useState(false);

  const getInjectorMetadata = (api) => {
    
    return {
      chain: api.runtimeChain.toString(),
      specVersion: api.runtimeVersion.specVersion.toNumber(),
      tokenDecimals: api.registry.chainDecimals[0] || 18,
      tokenSymbol: api.registry.chainTokens[0] || "AVAIL",
      genesisHash: api.genesisHash.toHex(),
      ss58Format: isNumber(api.registry.chainSS58) ? api.registry.chainSS58 : 0,
      chainType: "substrate",
      icon: "substrate",

      /** !! IMPORTANT !!
       * This is the important part, we tell the extension how to handle our signedExtension (even if it seems it's already there)
       **/
      userExtensions: signedExtensions,
    };
  };

  const decodeCallData = async () => {
    if (!api || !callData) return;
    setIsDecoding(true);

    try {
      // Clean the input hex string
      const cleanHex = callData.startsWith("0x") ? callData : `0x${callData}`;

      // Convert hex to Uint8Array
      const callDataU8a = hexToU8a(cleanHex);

      // Try to decode as Call directly
      const decodedCall = api.createType("Call", callDataU8a);

      // Get the call index
      const callIndex = decodedCall.callIndex;

      // Find the call in metadata
      const { method, section } = api.registry.findMetaCall(callIndex);

      // Get the arguments
      const args = decodedCall.args.map((arg) => arg.toHuman());

      // Set the decoded information
      setDecodedData({
        section,
        method,
        args,
        callIndex: callIndex.toString(),
        raw: {
          hex: cleanHex,
          bytes: Array.from(callDataU8a),
        },
      });
    } catch (error) {
      // Try alternative decoding method if first attempt fails
      try {
        const metadata = api.runtimeMetadata.asLatest;

        // Get the first two bytes for pallet and call indices
        const callDataArray = Array.from(hexToU8a(callData));
        const palletIndex = callDataArray[0];
        const callIndex = callDataArray[1];

        // Find the pallet
        const pallet = metadata.pallets.find((p) => p.index.toNumber() === palletIndex);

        if (!pallet) {
          throw new Error(`Pallet with index ${palletIndex} not found`);
        }

        // Get the calls for this pallet
        if (!pallet.calls.isSome) {
          throw new Error(`No calls found for pallet ${pallet.name.toString()}`);
        }

        const calls = pallet.calls.unwrap();
        const callVariants = calls.type.def.asVariant.variants;
        const call = callVariants.find((v) => v.index.toNumber() === callIndex);

        if (!call) {
          throw new Error(`Call with index ${callIndex} not found`);
        }

        setDecodedData({
          section: pallet.name.toString(),
          method: call.name.toString(),
          args: "Arguments could not be decoded. Raw data provided.",
          raw: {
            hex: callData,
            bytes: callDataArray,
          },
          indices: {
            pallet: palletIndex,
            call: callIndex,
          },
        });

        console.log("Decoded using fallback method:", {
          pallet: pallet.name.toString(),
          call: call.name.toString(),
        });
      } catch (fallbackError) {
        toast.error("Fallback decode error:", fallbackError);
        setDecodedData({
          error: `${error.message}\nFallback error: ${fallbackError.message}`,
          input: callData,
        });
      }
    } finally {
      setIsDecoding(false);
    }
  };

  const signTransaction = async () => {
    if (!api || !selectedAccount || !decodedData || decodedData.error) {
      toast.error("Incomplete setup: Make sure the API is connected, wallet is selected, and the transaction data is decoded.");
      return;
    }
    try {
      const metadataMain = await api.rpc.state.getMetadata();
      console.log("🚀 ~ signTransaction ~ metadataMain:", metadataMain)
      const injector = await web3FromSource(selectedAccount.meta.source);
      const metadata = getInjectorMetadata(api);
      if (!api) await injector.metadata.provide(metadata);
      const fromAddress = selectedAccount?.address;
      const toAddress = decodedData?.args[0]?.Id;
      const randomAmount = new BN(decodedData?.args[1]?.split(",")?.join(""));
      const amountInAvail = randomAmount.toString()?.slice(0, randomAmount?.toString()?.length - 18);

      // Create a extrinsic, transferring randomAmount units to toAddress.
      const transfer = await api.tx.balances.transferKeepAlive(toAddress, randomAmount);

      // Sign and Send the transaction
      await transfer.signAndSend(fromAddress, { signer: injector.signer, appId: "0" }, ({ events = [], status }) => {
        if (status.isInBlock) {
          toast.success("Successful transfer of " + amountInAvail + " Avail with hash" + status.asInBlock.toHex(), { duration: "4000" });
        } else if (status?.type?.toLowerCase() === "ready" || status?.type?.toLowerCase() === "broadcast" || status?.type?.toLowerCase() === "finalized") {
          toast.success("Status of transfer: " + status.type);
        } else {
          toast.error("Status of transfer: " + status.type);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          console.log(phase.toString() + " : " + section + "." + method + " " + data.toString());
        });
      });
    } catch (err) {
      toast.error(err.message);
      return console.log(err);
    }
  };

  if (!api) return <Loader />;

  return (
    <Fragment>
      <section className="common-table custom-decode-table">
        <Card>
          <CardHeader>
            <h4>Avail Transaction Decoder </h4>
          </CardHeader>
          <CardBody>
            <TableTop noPaginate noSearchbar />
            <Row className="g-3">
              <Col sm="12">
                <FormGroup className="form-group custom-label-data">
                  <label className="mb-2 form-label ">Avail Transaction Data :</label>
                  <textarea className="form-control search-transaction" placeholder="Enter Avail transaction call data (hex format)..." value={callData} onChange={(e) => setCallData(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>

            <Button color="transparent" className="btn-dark me-2" onClick={decodeCallData}>
              {isDecoding ? "Decoding..." : "Decode"}
            </Button>
            <Button color="lo" className="border border-1" onClick={signTransaction}>
              Sign Transaction
            </Button>

            {decodedData && (
              <>
                <div className="card data-wallet-card mt-4">
                  <div className="card-header border-0 p-0">
                    <div className="row">
                      <div className="col-3">
                        <div className="custom-data-label">
                          <label>decoded call</label>
                          <span>{decodedData?.section}</span>
                        </div>
                      </div>
                      <div className="col-5">
                        <div>
                          <span>{decodedData?.method}</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div>
                          <span>See [`Pallet::transfer_keep_alive`].</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="custom-wallet-body">
                      <div className="custom-ring">
                        <img className="img-fluid" src={dynamicSvg("ring-wallet.svg")} alt="icon" />
                      </div>
                      <span className="custom-data-label">dest: MultiAddress</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{decodedData?.args?.Id}</span>
                      </div>
                    </div>
                    <div className="custom-wallet-body mt-3">
                      <span className="custom-data-label">value: Compact'</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{decodedData?.args?.[1]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer custom-wallet-footer border-0">
                    <div className="custom-data-call">
                      <span className="custom-data-title d-block">encoded call hash</span>
                      <p>0xe8e02c790c4d3723f3cafd4dfdf95688435b98d5c0da2032f902fbe80492edd3</p>
                    </div>
                    <div onClick={() => handleCopyCode("0xe8e02c790c4d3723f3cafd4dfdf95688435b98d5c0da2032f902fbe80492edd3", "Hash")} style={{ cursor: "pointer" }}>
                      <i className="fa-regular fa-copy" />
                      {/* <img className="img-fluid" src={dynamicSvg("copy-link.svg")} alt="icon" /> */}
                    </div>
                  </div>
                </div>
                <div class="custom-roll-card">
                  <div class="custom-card__header">Encoding Details</div>
                  <div class="custom-card__body">
                    <p>
                      <span>Call Index:</span> {decodedData?.callIndex}
                    </p>
                    <p>
                      <span>Dest:</span> {decodedData?.args[0]?.Id}
                    </p>
                    <p>
                      <span>Value:</span> 13000064a73b6e00d
                    </p>
                    <div className="custom-data-link mt-2">
                      <Link target="_blank" to={`https://explorer.avail.so/?rpc=wss://turing-rpc.avail.so/ws#/extrinsics/decode/${decodedData?.raw?.hex}`}>
                        View Link
                      </Link>
                      <i className="fa-solid fa-up-right-from-square ms-1"></i>
                    </div>
                  </div>
                </div>
              </>
            )}
          </CardBody>
        </Card>
        {/* </Col>
        </Row> */}
      </section>
    </Fragment>
  );
};

export default AvailWalletContainer;
