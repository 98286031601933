import React from "react";
import { formatAmount } from "../../../Utils";

const DexVolumeCard = ({ dexVolumeChanges, ethVolume, baseDexVolume ,ethDexVolumeChanges,baseDexVolumeChanges }) => {
  console.log("🚀 ~ DexVolumeCard ~ ethVolume:", ethVolume)
  console.log("🚀 ~ DexVolumeCard ~ dexVolumeChanges:", dexVolumeChanges)
  return (
    <div className="price-usd">
      <h5 className="text-center"> Prev Dex Volume</h5>
      <div className="custom-price">
        <div className={`price badge-light-`}>
          <div className="dex-volume">
            <div className={`volume badge-light-${ethDexVolumeChanges > 0 ? "success" : ethDexVolumeChanges < 0 ? "danger" : "warning"}`}>
              <h5>AVAIL/USDC ETH</h5>
              <div className="d-flex">
                <h6>{formatAmount(ethVolume) || 0}</h6> {' '}
                <span>
                  <i className={`fa-solid fa-arrow-${ethDexVolumeChanges > 0 ? "trend-up" : ethDexVolumeChanges < 0 ? "trend-down" : ""}`}></i><div className="usd">{ethDexVolumeChanges || 0} %</div>
                </span>
              </div>
            </div>
            <div className={`volume badge-light-${baseDexVolumeChanges > 0 ? "success" : baseDexVolumeChanges < 0 ? "danger" : "warning"}`}>
              <h5>AVAIL/USDC BASE</h5>
              <div className="d-flex">
                <h6>{formatAmount(baseDexVolume) || 0}</h6>
                <span>
                  <div className="usd">
                    <i className={`fa-solid fa-arrow-${baseDexVolumeChanges > 0 ? "trend-up" : baseDexVolumeChanges < 0 ? "trend-down" : ""}`}></i>{baseDexVolumeChanges || 0} %
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DexVolumeCard;
