import React from "react";
import DexVolumeCard from "./DexVolumeCard";
import HoldersDetailCard from "./HoldersDetailCard";
import PriceDetailCard from "./PriceDetailCard";
import VolumeDetailCard from "./VolumeDetailCard";

const AvailInfoCards = ({ baseDexVolumeChanges, ethDexVolumeChanges, priceChange, coinMarketData, volumeChange, dexVolumeChanges, baseDexVolume, holdersChangePerDay, holderPercentage, ethVolume }) => {
  return (
    <div className="avail">
      <PriceDetailCard coinMarketData={coinMarketData} priceChange={priceChange} />
      <VolumeDetailCard coinMarketData={coinMarketData} volumeChange={volumeChange} />
      <DexVolumeCard baseDexVolumeChanges={baseDexVolumeChanges} ethDexVolumeChanges={ethDexVolumeChanges} baseDexVolume={baseDexVolume} dexVolumeChanges={dexVolumeChanges} ethVolume={ethVolume} />
      <HoldersDetailCard holderPercentage={holderPercentage} holdersChangePerDay={holdersChangePerDay} />
    </div>
  );
};

export default AvailInfoCards;
