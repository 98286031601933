import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { deelPaymentHistoryRefreshApi, getDeelPaymentHistoriesApi } from "../../../Api/ApiList";
import { useGetApi } from "../../../Api/useGetApi";
import { useBridgerTransfer } from "../../../Context/BridgeTransferContext";
import { PaymentHistoryTableColumnData } from "../../../Data/TableColumnsData";
import useDebounce from "../../../Hooks/useDebounce";
import useDebouncedObject from "../../../Hooks/useDebounceObject";
import Loader from "../../../Layout/Loader";
import { toMilliseconds } from "../../../Utils";
import AdvanceFilter from "../../Common/AdvanceFilter";
import CommonPagination from "../../Common/CommonPagination";
import CommonTable from "../../Common/CommonTable";
import { calculateRemainingTime, handleRefresh } from "../../Common/CustomFunctions";
import TableTop from "../../Common/TableTop";

const PaymentHistory = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const localKey = "paymentHistoryLastRefreshTime";
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState();

  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const debouncedObject = useDebouncedObject(advancedFilter, 500);
  const { editModal, searchTerm, setSearchTerm } = useBridgerTransfer();
  const { startDate = null, endDate = null } = debouncedObject;
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: peoplesData, refetch: refetchGetData } = useGetApi({ url: getDeelPaymentHistoriesApi, params, options: { enabled: !!Object.keys(params).length }, successFunction: false, navigate: navigate });
  const { data: refreshedData, refetch: refreshData } = useGetApi({ url: deelPaymentHistoryRefreshApi, options: { enabled: false, refetchOnMount: false }, successFunction: false, navigate: navigate });

  useEffect(() => {
    if (searchTerm?.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // send advance filter params in api
  useEffect(() => {
    setParams({
      ...params,
      startDate: startDate,
      endDate: endDate,
    });
  }, [startDate, endDate]);

  // total pages of data
  useEffect(() => {
    if (peoplesData) {
      setTotalPages(peoplesData.totalPages);
    }
  }, [peoplesData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
    }));
  }, [search, page, limit]);

  useEffect(() => {
    if (search?.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem(localKey);
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  if (isLoadingGetData) return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Billing / Payment History</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              extraComponents={[
                {
                  jsx: (
                    <>
                      <span id="refresh-tooltip" style={{ cursor: "pointer" }}>
                        <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
                          <div className="light-box" onClick={() => handleRefresh(localKey, refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime)}>
                            <i className="fa-solid fa-rotate"></i>
                          </div>
                        </Button>
                      </span>
                      <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
                        <p>
                          {tooltipContent} <b>{remainingTime}</b>{" "}
                        </p>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
              advanceFilter={{ setShowFilter: setShowFilter, showFilter: showFilter }}
              limit={limit}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
            />
            {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} fieldNotToInclude={["minAmount", "maxAmount", "fromAddress", "toAddress"]} /> : null}
            <div className="custom-scrollbar table-responsive">
              <CommonTable columns={PaymentHistoryTableColumnData} data={peoplesData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table transfer-table people-table" />
            </div>
            {!isLoadingGetData && peoplesData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
    </Fragment>
  );
};

export default PaymentHistory;
